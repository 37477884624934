@import "./index";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

// .spacer-15 {
//   height: 15px;
// }

.header {
  position: relative;
  height: 75px;
  background-image: linear-gradient(to right, #000000, #590505, #000000);
}

.footer {
  position: relative;
  height: 75px;
  background-image: linear-gradient(to right, #000000, #590505, #000000);
}

.footer__text-wrap {
  text-align: center;
  position: absolute;
  width: 100%;
  top: 22px;
}

.footer__text {
  color: #ffffff;
  font-weight: 100;
  font-size: 20px;
}

.header__container {
  // width: 33.333%;
  width: 40%;
}

.header-line {
  height: 2px;
  margin-bottom: 4px;
  background-image: linear-gradient(to right, #ffffff, rgba(255, 255, 255, 0));
}

.header-line-1 {
  width: 20%;
}

.header-line-2 {
  width: 40%;
}

.header-line-3 {
  width: 60%;
}

.header-line-4 {
  width: 80%;
}

.header-line-5 {
  width: 100%;
}

.header__container--left {
  top: 45px;
  position: relative;
}

.header__container--right {
  top: 45px;
  right: 0px;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  position: absolute;
}

.footer__container {
  // width: 33.333%;
  width: 40%;
}

.footer__container--left {
  top: 4px;
  position: relative;
}

.footer__container--right {
  top: 4px;
  right: 0px;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  position: absolute;
}

.logo-container {
  text-align: center;
}

.logo {
  width: 120px;
  position: relative;
  top: -24px;
}

.black-line {
  height: 1px;
  background-color: #000000;
  margin-top: 15px;
}

.black-line--top {
  width: calc(100% - 32px);
}

.black-line--bottom {
  margin-bottom: 15px;
}

.five-lines--1 {
  height: 2px;
  background-color: #590505;
}

.five-lines--2 {
  height: 2px;
  background-color: #000000;
  opacity: 0.8;
}

.five-lines--3 {
  height: 2px;
  background-color: #590505;
  opacity: 0.6;
}

.five-lines--4 {
  height: 2px;
  background-color: #000000;
  opacity: 0.4;
}

.five-lines--5 {
  height: 2px;
  background-color: #590505;
  opacity: 0.2;
}

.five-lines--top {
  margin-bottom: 4px;
}

.five-lines--bottom {
  margin-top: 4px;
}

.five-lines--top {
  margin-bottom: 4px;
}

.mob-menu-circle {
  display: none;
  background-color: #ffffff;
  border: 2px solid #590505;
  border-radius: 50%;
  position: absolute;
  left: calc(50% - 20px);
  height: 35px;
  width: 35px;
  z-index: 2;
  /* top: 33px; */
  margin-top: -6px;
  box-shadow: 0px 3px 10px #000000;
}

.mob-menu-inner-line-1 {
  height: 2px;
  width: 27px;
  background-color: #000000;
  opacity: 0.8;
  margin-top: 10px;
  margin-left: 4px;
}

.mob-menu-inner-line-2 {
  height: 2px;
  width: 27px;
  background-color: #590505;
  opacity: 0.6;
  margin-top: 4px;
  margin-left: 4px;
}

.mob-menu-inner-line-3 {
  height: 2px;
  width: 27px;
  background-color: #000000;
  opacity: 0.4;
  margin-top: 4px;
  margin-left: 4px;
}

.mob-nav-container {
  width: 100%;
  position: absolute;
  background-color: #ffffff;
  text-align: center;
  z-index: 1;
}

.mob-nav-link {
  //display: none;
  padding: 0px;
  font-size: 0px;
  transition: padding 0.5s, font-size 0.5s, display 0.5s;
}

.mob-nav-link-animate {
  //display: block;
  padding: 10px;
  font-size: 24px;
}

.mob-nav-link > a {
  color: #000000;
  text-decoration: none;
}

.mob-nav-line-1 {
  background-color: #590505;
  width: 0%;
  height: 2px;
  //transition: width 0.5s, display 0.5s;
  display: none;
}

.mob-nav-line-2 {
  background-color: #000000;
  opacity: 0.8;
  width: 0%;
  height: 2px;
  //transition: width 0.5s, display 0.5s;
  display: none;
}
.mob-nav-line-3 {
  background-color: #590505;
  opacity: 0.6;
  width: 0%;
  height: 2px;
  //transition: width 0.5s, display 0.5s;
  display: none;
}
.mob-nav-line-4 {
  background-color: #000000;
  opacity: 0.4;
  width: 0%;
  height: 2px;
  //transition: width 0.5s, display 0.5s;
  display: none;
}
.mob-nav-line-5 {
  background-color: #590505;
  opacity: 0.2;
  width: 0%;
  height: 2px;
  //transition: width 0.5s, display 0.5s;
  display: none;
}

.mob-nav-line-animation {
  display: block;
  //height: 2px;
  width: 100%;
}

.quote {
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  margin: 10px 0 10px 0;
}

.quote-mobile {
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  margin: 15px 15% 15px 15%;
  display: none;
}

.container-heading {
  display: inline-block;
  font-size: 40px;
  font-weight: 400;
  border-bottom: 2px solid #000000;
  margin: -3px 0 50px 0;
}

.main-container {
  margin: 30px 15% 0px 15%;
}

.main-container--flex {
  display: flex;
  flex-wrap: wrap;
}

.section-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.category-container {
  position: relative;
  text-align: center;
  // min-width: 300px;
  // max-width: 600px;
  width: 23%;
  box-sizing: border-box;
  margin-bottom: 30px;
  border: 1px solid #000000;
  border-top-right-radius: 7px;
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  //border-radius: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.category-container__wrap {
  display: inline-block;
  width: 100%;
}

.category-container:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.category-container__title {
  color: #000000;
  font-weight: 400;
  z-index: 1;
  margin: -2px 0px 0px 4px;
}

.category-container__line {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.75);
  position: absolute;
  bottom: 0px;
  text-align: left;
  // border-radius: 10px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  z-index: 0;
}

.category-container__image {
  width: 100%;
  //border: 2px solid #000000;
  cursor: pointer;
  vertical-align: top;
  // border-top-right-radius: 10px;
  // border-top-left-radius: 10px;
  border-radius: 5px;
}

.category-description {
  margin: 0px 15% 0px 15%;
}

.category-description__text {
  font-size: 20px;
  margin: 0px;
  //font-weight: 400;
  border-top: 1px solid #000000;
  padding-top: 20px;
}

.info-container {
  margin: 0 15% 0 15%;
}

.info-container__heading {
  display: inline-block;
  font-size: 40px;
  font-weight: 400;
  border-bottom: 2px solid #000000;
}

.info-container__paragraph {
  font-size: 20px;
}

.info-container__span {
  color: #590505;
}

.nav-container {
  padding: 0 15% 0 15%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.nav-container__link {
  font-size: 20px;
  font-weight: 400;
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  display: inline;
  // padding: 22px 2px 3px 0px;
  margin: 18px 0 7px 0;
}

.back-to-top-button {
  display: none;
  width: 35px;
  height: 35px;
  border: 2px solid #590505;
  border-radius: 50%;
  position: absolute;
  left: calc(50% - 20px);
  margin-top: 15px;
}

.up-arrow {
  font-size: 24px;
}

$outline: #590505;
$shadow: #590505;
$shadow2: #590505;

.active {
  color: #590505;
  border-bottom: 2px solid #590505;
  /* text-decoration: underline; */
  /* text-shadow: 1px 1px 1px rgba(0, 0, 0, 1), 0px 0px 25px rgba(0, 0, 0, 1); */
  // text-shadow: -1px -1px 0 $outline, 1px -1px 0 $outline, -1px 1px 0 $outline,
  //   1px 1px 0 $outline, 0 0 2px #000000, 0 0 14px $shadow, 0 0 10px $shadow,
  //   0 0 13px $shadow, 0 0 20px $shadow2;
}

.mob-active {
  color: #590505 !important;
  text-shadow: 1px 0px 1px #cccccc, 0px 1px 1px #eeeeee, 2px 1px 1px #cccccc,
    1px 2px 1px #eeeeee, 3px 2px 1px #cccccc, 2px 3px 1px #eeeeee,
    4px 3px 1px #cccccc, 3px 4px 1px #eeeeee, 5px 4px 1px #cccccc,
    4px 5px 1px #eeeeee, 6px 5px 1px #cccccc, 5px 6px 1px #eeeeee,
    7px 6px 1px #cccccc;
  text-decoration: none;
}

.search-container__input {
  position: absolute;
  right: 30px;
  height: 20px;
  min-width: calc(15% - 60px);
  margin-top: 4px;
  border: 1px solid #000000;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  color: #000000;
  padding: 0 10px 0 10px;
}

.search-container__icon {
  width: 22px;
  right: 4px;
  top: 79px;
  position: absolute;
}

.search-container__input:focus {
  outline: none;
  background-color: #ffffff;
  box-shadow: 0 1px 3px rgba(89, 5, 5, 0.12) inset,
    0 1px 2px rgba(89, 5, 5, 0.2) inset;
}

.search-none {
  font-weight: bold;
}

::placeholder {
  color: #000000;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #000000;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #000000;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

@media only screen and (max-width: 1250px) {
  .category-container {
    width: 40%;
  }
}

@media only screen and (max-width: 1100px) {
  .quote {
    display: none;
  }
  .quote-mobile {
    display: block;
  }
  .black-line--top {
    margin-top: 30px;
  }
  .black-line--bottom {
    margin-bottom: 30px;
  }
  .search-container__input {
    //margin-top: 19px;
    margin-top: 19px;
  }
  .search-container__icon {
    top: 94px;
  }
}

@media only screen and (max-width: 950px) {
  .category-container {
    width: 48%;
  }
}

@media only screen and (max-width: 768px) {
  .mob-menu-circle {
    display: block;
  }
  .nav-container__link {
    font-size: 0px;
    margin: 26px 0 26px 0;
  }
  .back-to-top-button {
    display: block;
  }
}

@media only screen and (min-width: 768px) {
  .mob-nav-container {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .category-container {
    width: 100%;
  }
  .black-line--top {
    //width: 93%;
    width: calc(5% + 2px);
  }
  // .search-container__input {
  //   min-width: calc(100% - 56px);
  //   left: 4px;
  // }
  // .search-container__icon {
  //   right: 4px;
  // }

  .search-container__input {
    //margin-top: 19px;
    //min-width: calc(100% - 66px);
    min-width: calc(90% - 58px);
    margin-top: 15px;
    height: 30px;
    left: 5%;
  }
  .search-container__icon {
    //top: 94px;
    top: 90px;
    width: 32px;
    right: 5%;
  }
  .quote-mobile {
    font-size: 16px;
    margin: 25px 5% 15px 5%;
  }
  .main-container {
    margin: 30px 5% 0px 5%;
  }
  .info-container {
    margin: 0 5% 0 5%;
  }
  .nav-container {
    padding: 0 5% 0 5%;
  }
}
